import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  page: {
    logo: {
      src: {
        light: "https://limitless-s3.nyc3.digitaloceanspaces.com/alteumx/logo-morado.svg",  // Ruta al logo para el tema claro
        dark: "https://limitless-s3.nyc3.digitaloceanspaces.com/alteumx/logo-blanco.svg"  // Ruta al logo para el tema claro
      },
      width: "100px", // Ajusta el tamaño del logo si es necesario
    },
  },
  topNavigation: [
    { id: "start", label: "Documentation" },
    //{ id: "api", label: "API Reference" },
  ],
  sidebar: {
    start: [
      {
        type: "category",
        label: "Get Started",
        items: ["overview", "account", "payX"],
        //icon: "sparkles"
      },
      {
        type: "category",
        label: "Integration",
        //icon:"workflow",
        items: ["test/html-button", "test/post-request"],
      },
      {
        type: "category",
        label: "Tips",
        //icon:"badge-info",
        items: ["deposit", "withdraw"],
      }
    ]
  },
  redirects: [
    { from: "/", to: "/start/overview" },
    { from: "/start", to: "/start/overview" },
  ],
  apis: {
    type: "file",
    input: "apis/openapi.yaml",
    navigationId: "api",
  },
  docs: {
    files: "/pages/**/*.{md,mdx}",
  },
  metadata:{
    title:"PayX - Documentation",
    favicon:"https://limitless-s3.nyc3.digitaloceanspaces.com/alteumx/Alteumx-Icono.ico",
    description:"PayX Documentation"
  }
};

export default config;
